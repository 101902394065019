// JS
import '../../custom/js/custom/index';


// Base SCSS
import '../../custom/scss/common/base.scss';
import '../../custom/scss/custom/layout.scss';

// Components SCSS
import '../../custom/scss/custom/components/material-card/material-card.scss';
import '../../custom/scss/custom/components/tabs/tabs.scss';
import '../../custom/scss/custom/components/policy-listing/policy-listing.scss';
import '../../custom/scss/custom/components/agent-card/agent-card.scss';
import '../../custom/scss/custom/components/messaging/messaging.scss';
import '../../custom/scss/custom/components/policy-highlight/policy-highlight.scss';
import '../../custom/scss/custom/components/secondary-nav/secondary-nav.scss';
import '../../custom/scss/custom/components/policy-details/policy-details.scss';
import '../../custom/scss/custom/components/policy-documents/policy-documents.scss';
import '../../custom/scss/custom/components/page-title/page-title.scss';
import '../../custom/scss/custom/components/draft-claim/draft-claim.scss';
import '../../custom/scss/custom/components/stepper/stepper.scss';
import '../../custom/scss/custom/components/form/form.scss';
import '../../custom/scss/custom/components/modal/modal.scss';
import '../../custom/scss/custom/components/claim-tile/claim-tile.scss';
import '../../custom/scss/custom/components/popper/popper.scss';
import '../../custom/scss/custom/components/policy-forms/policy-forms.scss';
import '../../custom/scss/custom/components/panel/panel.scss';
import '../../custom/scss/custom/components/security-questions/security-questions.scss';
import '../../custom/scss/custom/components/success-message/success-message.scss';
import '../../custom/scss/custom/components/login/login.scss';
import '../../custom/scss/custom/components/change-password/change-password.scss';
import '../../custom/scss/custom/components/credit-monitoring/credit-monitoring.scss';
import '../../custom/scss/custom/components/preferences/preferences.scss';
import '../../custom/scss/custom/components/payments/payments.scss';
import '../../custom/scss/custom/components/claim-detail/claim-detail.scss';
import '../../custom/scss/custom/components/breadcrumb/breadcrumb.scss';
import '../../custom/scss/custom/components/card/card.scss';
import '../../custom/scss/custom/components/card/card-table.scss';
import '../../custom/scss/custom/components/accordion/accordion.scss';
import '../../custom/scss/custom/components/page-note/page-note.scss';
import '../../custom/scss/custom/components/card/card-grid.scss';
import '../../custom/scss/custom/components/card/card-agent.scss';
import '../../custom/scss/custom/components/card/card-link.scss';
import '../../custom/scss/custom/components/card/card-form.scss';