import $ from 'jquery';

class AccountOverview {
    constructor() {
    }

    init() {
        if($('.js-has-investments').length) {
            $('.js-overview-title').text(`Your Policies (& Investments)`)
        }
    }

}

export default AccountOverview;

$(() => {
	const accountOverview = new AccountOverview();
	accountOverview.init();
});